<template>
  <div class="wrapper">
    <Section>
      <HeadingBlock :data="{ blockTitle: 'Favoritos' }" />
    </Section>
    <div class="favorite-container">
      <UsedCars v-for="(car, index) in cars" :key="index" :data="car" />
    </div>
  </div>
</template>

<script>
import { getFavorites } from "@/services/functions.js";
import HeadingBlock from "@/components/Blocks/HeadingBlock.vue";
import Section from "@/components/Section/Section.vue";
import UsedCars from "@/components/UsedCars/UsedCars.vue";
import { createUsedCarsArray } from "@/services/dataManagement.js";
import { mapGetters } from "vuex";
export default {
  name: "Favorites",
  data() {
    return {
      cars: [],
    };
  },
  components: {
    HeadingBlock,
    Section,
    UsedCars,
  },
  methods: {
    createUsedCarsArray,
    getFavorites,
  },
  created() {
    if (this.$store.state.user.loginToken) {
      this.getFavorites(this.$store.state.user.loginToken).then((response) => {
        if (response.data) {
          this.cars = this.createUsedCarsArray(response.data);
        }
      });
    }
  },
  computed: {
    ...mapGetters("user", {
      user: "getUserData",
    }),
  },
};
</script>

<style lang="scss" scoped>
.favorite-container {
  align-items: stretch;

  text-align: left;
  margin-bottom: 1rem;
  div {
    margin-top: 0 !important;
    @include respond-to-max("md") {
      margin-bottom: 2rem;
    }
  }
  @include respond-to-min("md") {
    display: grid;
    flex: 1;
    gap: 1.5rem;
    grid-template-columns: repeat(3, 1fr);
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
</style>
